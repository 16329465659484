
export const routes = {
    stylori: '/stylori',
    PricingPage: '/pricingPage',
    Cart: '/cart',
    Checkout: '/Checkout',
    Register: '/register',
    UserLogin: '/login',
    UserRegister: '/registers',
    HomePageStylori: '/',
    Account: `/account${"-" + window.location.pathname.split("-")[1]}`,
    Silver: '/styloriSilver',
    Faqs: '/faqs',
    Stories: '/stories',
    Collection: '/collections',
    ProductCare: '/productcare',    
    Shipping: '/deliveryreturns',
    PrivacyPolicy: '/privacypolicy',
    sitemap:'/sitemap',
    // sitemaps:'/sitemap.xml',
    sitemapage:'/sitemap.xml',
    robots:'/robots.txt',
    TermsConditions: '/termsconditions',
    AboutUs: '/aboutus',
    ForgotPassword: '/forgotpassword',
    ResetPassword: '/resetpassword',
    ChangePassword: '/changepassword',
    paymentsuccess: '/paymentsuccess',
    paymentfail: '/paymentfail',
    paymenthidden: '/paymenthidden',
    Education: '/education',
    ContactUs: '/contactus',
    Careers: "/careers",
    styloriSilverCollections:"/silverCollections",
    cmsRoutes: `/static${"-" + window.location.pathname.split("-")[1]}`,
    styloriBlog:"/styloriBlog"
}

export default routes;