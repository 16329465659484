export const API_URL = "https://api.stylori.com";
// export const API_URL = "https://api-staging-new.stylori.com";

// https://api-staging-new.stylori.com/
//"http://styloriapi-env-1.eba-wvg7uw3t.ap-south-1.elasticbeanstalk.com";//"https://api.stylori.com";//https://api-staging-new.stylori.com
// https://api-alpha.stylori.net

//http://192.168.0.106:8000

export const HOME_PAGE_URL = "";
export const CDN_URL = "https://assets.stylori.com/";
// export const CDN_URL =
// "https://styloriimages-staging.s3.ap-south-1.amazonaws.com/";
// export const CDN_URL = "https://assets.stylori.com/"
// export const CDN_URL =
//   "https://styloriimages-staging.s3.ap-south-1.amazonaws.com/";

export const GOOGLE_CLIENT_ID =
  "727432939388-qmtkseqsijuge2nsarbtups82o1vslvi.apps.googleusercontent.com";
export const FACEBOOK_APP_ID = "238023071773183";


// export const INSTA_ID = "6420303178027898"
// export const INSTA_TOKEN = "IGQVJVY2pnYVI4bGxwX2gzSk9JZAzZACZAlgwZAVFZASXVNQ0NIbmVnd3pGVG1KN1lrM05CbXlNeGlGNEpTRzJldldHdW5lS2RqSWVlZAmd5YWFhSlplNDVUcFFIdGt5OEdMYUpJWXVSQWRNcmlyWnZAzNkYwTwZDZD"

export const INSTA_ID = "791985682271087"
export const INSTA_TOKEN = "IGQWRQaTlzbXAxQWYtSzNjZAjZAkX0tibThDM1llNGJOc05EckZAUTm1GVTlHcXJzck1ta3dpbnNveldkVmQxQzNuTmxTUEUwWmVLYU02WHRJcGlJdjROd0N2bWdEc2ZAsOElWbmhRRXVEWWtmSlNJbUlwb3NYY3I2c3MZD"
